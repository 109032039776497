import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';
import { TransactionDto, TransactionFindAll, TransactionWithResultedBalanceDto } from '../../proto/balance_pb';
import { UserInfoDto } from '../../proto/auth_pb';
import { useStores } from '../../stores/app';
import { Column, CustomTable } from '../../components/table';
import { BalanceMapper } from '../../core';

interface ITransactionHistory {
  userId: number;
  userName: string;
  email: string;
  date: number;
  creditBoints?: number;
  debitBoints?: number;
  transactionDetail?: number;
  resultBalanceAmount: number;
  appVersion?: string;
}

const transactionType = (val: number): string => {
  return BalanceMapper[val] || 'unknown';
};

const dateFormat = (value: any): string => {
  if (Number(value) !== 0) {
    return moment(+value).format('MM/DD/YYYY, HH:mm:ss');
  } else {
    return '-';
  }
};

const columns: Column[] = [
  { id: 'userId', label: 'UserID', minWidth: 80, disableSort: true },
  { id: 'userName', label: 'Username', minWidth: 120, disableSort: true },
  { id: 'appVersion', label: 'App Version', minWidth: 120, disableSort: true },
  { id: 'date', label: 'Date', minWidth: 170, format: dateFormat },
  { id: 'creditBoints', label: '-Boints', minWidth: 80, disableSort: true },
  { id: 'debitBoints', label: '+Boints', minWidth: 80, disableSort: true },
  {
    id: 'transactionDetail',
    label: 'Transaction Detail',
    minWidth: 170,
    format: transactionType,
    disableSort: true
  },
  { id: 'resultBalanceAmount', label: 'Total Boints', minWidth: 100, disableSort: true },
];

const generateHistoryItems = (
  users: UserInfoDto.AsObject[],
  transactions: any,
) => {
  const historyItems: ITransactionHistory[] = [];

  transactions.forEach((item: TransactionWithResultedBalanceDto.AsObject) => {
    const user: UserInfoDto.AsObject = users.filter(
      (user: UserInfoDto.AsObject) => item.userid === user.id,
    )[0];

    const temp: ITransactionHistory = {
      userId: user?.id || 0,
      userName: user?.username,
      email: user?.email,
      date: item.createdate,
      transactionDetail: item.type,
      resultBalanceAmount: item.resultbalanceamount, // TODO add this field item.targetcurrentbalance
      appVersion: item.additionalparams ? item.additionalparams?.['currentUserVersion'] : '-',
    };

    temp.creditBoints = item.credit;
    temp.debitBoints = item.debit;

    historyItems.push(temp);
  });

  return historyItems;
};

export const Transactions = observer(() => {
  const { transactionStore, userStore } = useStores();
  const [data, setData] = useState<ITransactionHistory[]>([]);

  const loadTransactions = (dto: TransactionFindAll.AsObject) =>
    transactionStore.getTransactions(dto);

  const getUsersInfo = (userIds: number[]) =>
    userStore.getPlayersInfoList({ userIds });

  useEffect(() => {
    const userIds: number[] = [];

    if (transactionStore.transactions.dataList.length === 0) {
      setData(generateHistoryItems([], []));
    } else {
      transactionStore.transactions.dataList.forEach(
        (item: TransactionDto.AsObject) => {
          if (!userIds.includes(item.userid)) {
            userIds.push(item.userid);
          }
        },
      );
      if (userIds.length) {
        getUsersInfo(userIds);
      }
    }
  }, [transactionStore.transactions.dataList]);

  useEffect(() => {
    if (userStore.usersInfo.info.length) {
      setData(
        generateHistoryItems(
          userStore.usersInfo.info,
          transactionStore.transactions.dataList,
        ),
      );
    }
  }, [userStore.usersInfo.info]);

  return (
    <CustomTable
      data={data}
      isDataSliced={true}
      totalCount={transactionStore.transactions.totalcount}
      columns={columns}
      isSearchShow={true}
      searchInputText="Type UserID here"
      customSearchParam="userid"
      paramsForAction={{ targetid: null }}
      requestAction={loadTransactions}
    />
  );
});
